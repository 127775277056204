export default function Cookie() {
    var self = this;
    self.document = document;

    self.setExpiration = function (expires) {
        return new Date(new Date().getTime() + parseInt(expires) * 1000 * 60 * 60 * 24);
    };

    self.setCookie = function (cookie) {
        self.document.cookie = cookie;
        return true;
    };

    self.createCookie = function (name, value, expires, path, domain) {
        var cookie = name + '=' + escape(value) + ';';

        if (expires) {
            // If it's a date
            if (expires instanceof Date) {
                // If it isn't a valid date
                if (isNaN(expires.getTime())) {
                    expires = new Date();
                }
            } else {
                expires = self.setExpiration(expires);
            }
            cookie += `expires=${expires.toUTCString()};`;
        }

        if (path) {
            cookie += `path=${path};`;
        }

        if (domain) {
            cookie += `domain=${domain};`;
        }

        self.setCookie(cookie);
    };

    self.getCookie = function (name) {
        const nameEquals = name + '=';
        const ca = self.document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEquals) === 0) {
                return c.substring(nameEquals.length, c.length);
            }
        }
        return null;
    };

    self.deleteCookie = function (name, path, domain) {
        // If the cookie exists
        if (self.getCookie(name)) {
            self.createCookie(name, '', -1, path, domain);
        }
    };
}